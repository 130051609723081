'use client';
import { GlobalErrorMessage } from '@wla/app/global-error-message';
import { cn } from '@wla/lib/helpers/cn';
import { trackError } from '@wla/lib/tracking/errors/error-tracking';
import localFont from 'next/font/local';

const jyskIcons = localFont({
  src: '../../../libs/icons/font/jysk-icons.woff2',
  variable: '--font-jysk-icons',
});

const openSans = localFont({
  src: '../fonts/open-sans-latin-variable-wghtOnly-normal.woff2',
  variable: '--font-open-sans',
});

// This has preload: false, because it is only used in through the RTE.
// So the browser will only load the font if needed.
const openSansItalic = localFont({
  src: '../fonts/open-sans-latin-variable-wghtOnly-italic.woff2',
  variable: '--font-open-sans-italic',
  preload: false,
});

// This has preload: false, because it is only used in through the RTE.
// So the browser will only load the font if needed.
const foxInTheSnow = localFont({
  src: '../fonts/fox_in_the_snow.woff2',
  variable: '--font-fox-in-the-snow',
  preload: false,
});

export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  trackError(error);
  const fontClasses = `${openSans.variable} ${foxInTheSnow.variable} ${jyskIcons.variable} ${openSansItalic.variable} font-sans`;

  return (
    <html>
      <body className={cn(fontClasses, 'text-base text-gray-1000')}>
        <GlobalErrorMessage />
      </body>
    </html>
  );
}
